import React, { useEffect } from "react";
import PageHeader from "../components/PageHeader";
/*import TeamSlider from '../components/TeamSlider';
import ImageSlider from '../components/ImageSlider';
import Button from '../components/Button';
import SectionHeading from '../components/SectionHeading';
import TestimonialSlider from '../components/TestimonialSlider';*/
import BackgroundAnimation from "../components/BackgroundAnimation";
//import faq from '../data/faq';
import { Link } from "react-router-dom";

// images
//import background_image_1 from '../../assets/images/patterns/pattern-1.jpg';
import about_image from "../../assets/pirates/svg/pirates-26.svg";
import { Helmet } from "react-helmet";
//import AppFeatureSingle from '../components/AppFeatureSingle';

const Activate = () => {
  useEffect(() => {
    console.log(window.location.search);
    //send result of cb transaction to api via fetch
    fetch(
      `https://staging-api.kndl-pdf.com/cb/activate${window.location.search}`
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Helmet>
        <title>Activated</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {/* page header - start */}
      <PageHeader
        title="Activated!"
        pages={[
          {
            title: "Home",
            to: "/",
          },
        ]}
      />
      {/* page header - end */}

      {/* about section - start */}
      <div className="about-section">
        <div className="about-section-wrapper">
          <div className="container">
            {/* first half - start */}
            <div className="row">
              <div className="col-lg-6 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2">
                <div className="about-section-content c-grey">
                  <div className="section-heading">
                    <div className="sub-heading c-blue upper ls-1">
                      <i className="las la-user-circle"></i>
                      <h5>Thank You!</h5>
                    </div>
                    <div className="main-heading c-dark">
                      <h1>Ahoy there, and a hearty congratulations!</h1>
                    </div>
                  </div>
                  <p className="paragraph-big">
                    We be delighted to welcome you aboard the KNDL Kraken - PDF
                    Unleashed crew! Thank you for choosin' our trusty Google
                    Chrome Extension to help you navigate the high seas of
                    Kindle e-books and webpages. Yer support means the world to
                    us, and we can't wait for you to experience the freedom and
                    adventure that awaits you with our treasure of a tool.
                  </p>
                  <p>
                    Install the extension, and let it guide you through the
                    process of unleashin' the power of yer Kindle e-books and
                    webpages in PDF format.{" "}
                    <strong>
                      Don't forget you need to be logged in with a chrome
                      profile that has the same email as you purchased the
                      extension with!
                    </strong>
                  </p>
                  <p>
                    Once again, thank you for joinin' our band of adventurers!
                    We be honored to have you with us, and we can't wait to see
                    the amazing things you'll accomplish with KNDL Kraken - PDF
                    Unleashed by yer side. As you embark on this journey, keep
                    an eye on the horizon and ensure you have the proper
                    permissions and licenses to be workin' with yer Kindle
                    e-books in PDF format. Sail responsibly, me hearties!
                  </p>
                  <h3>
                    <Link to="/download">Install the extension here!</Link>
                  </h3>
                  <p>
                    ClickBank is the retailer of products on this site.
                    CLICKBANK® is a registered trademark of Click Sales, Inc., a
                    Delaware corporation located at 1444 S. Entertainment Ave.,
                    Suite 410 Boise, ID 83709, USA and used by permission.
                    ClickBank's role as retailer does not constitute an
                    endorsement, approval or review of these products or any
                    claim, statement or opinion used in promotion of these
                    products.
                  </p>
                  <p>
                    Your purchase will be reflected as "ClickBank" or
                    "CLKBANK*COM"
                  </p>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-0 order-lg-2 col-md-8 offset-md-2 col-10 offset-1 order-1">
                <div className="about-section-image">
                  <div className="pattern-image pattern-image-1">
                    <div className="pattern-image-wrapper">
                      <img
                        style={{ backgroundColor: "#2863af" }}
                        className="drop-shadow-1"
                        src={about_image}
                        height="450"
                        width="500"
                        alt="pattern"
                      />
                      <div className="background-pattern background-pattern-radius drop-shadow-1">
                        <BackgroundAnimation />
                        <div className="background-pattern-gradient"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Activate;
