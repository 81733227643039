import React, { useState } from "react";

import PageHeader from "../components/PageHeader";
import BackgroundAnimation from "../components/BackgroundAnimation";
import ImageSlider from "../components/ImageSlider";
import Button from "../components/Button";
import SectionHeading from "../components/SectionHeading";
import PricingSlider from "../components/PricingSlider";

import faq from "../data/faq";

import pricing_image from "../../assets/pirates/svg/pirates-35.svg";
import background_image_1 from "../../assets/images/patterns/pattern-1.jpg";
import { Helmet } from "react-helmet";
import { useClickbankBanner } from "./useClickbankBanner";

const Pricing = () => {
  useClickbankBanner();

  return (
    <>
      <Helmet>
        <title>Pricing | KNDL Kraken - PDF Unleashed</title>
      </Helmet>
      {/* page header - start */}
      <PageHeader
        title="Pricing"
        pages={[
          {
            title: "Home",
            to: "/",
          },
          {
            title: "Pricing",
            to: "/pricing",
          },
        ]}
      />
      {/* page header - end */}

      {/* about section - start */}
      <div className="about-section">
        <div className="about-section-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2">
                <div className="about-section-content c-grey">
                  <div className="section-heading">
                    <div className="sub-heading c-blue upper ls-1">
                      <i className="las la-user-circle"></i>
                      <h5>pricing</h5>
                    </div>
                    <div className="main-heading c-dark">
                      <h1>A Treasure Trove of Pricing Plans!</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* about section - end */}

      {/* pricing section - start */}
      <div className="pricing-section">
        <div className="pricing-section-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                <SectionHeading
                  icon="las la-tags"
                  heading="KNDL Kraken"
                  subHeading="Pricing plans"
                  additionalClasses="center c-white"
                  mainHeadingClass="c-white"
                  subHeadingClass="c-white"
                />
              </div>
            </div>
            <PricingSlider disablePurchase={true} />
          </div>
          <div className="background-pattern background-pattern-1">
            <BackgroundAnimation />
            <div className="background-pattern-gradient"></div>
            <div className="background-pattern-bottom">
              <div
                className="image"
                style={{ backgroundImage: `url(${background_image_1})` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      {/* pricing section - end */}

      <div className="about-section">
        <div className="about-section-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2">
                <div className="about-section-content c-grey">
                  <div className="section-heading">
                    <div className="sub-heading c-blue upper ls-1">
                      <i className="las la-user-circle"></i>
                      <h5>pricing</h5>
                    </div>
                    <div className="main-heading c-dark">
                      <h1>A Treasure Trove of Pricing Plans!</h1>
                    </div>
                  </div>
                  <p className="paragraph-big">
                    Ahoy, me hearties! Welcome aboard the KNDL Kraken PDF
                    Unleashed pricing deck! Our treasure trove of plans offers
                    something for every e-book explorer seeking to liberate
                    their Kindle e-books and navigate the open seas of PDF
                    freedom*. Whether ye be a casual reader or a seasoned
                    literary captain, our affordable plans ensure smooth sailing
                    for all your conversion needs.
                  </p>
                  <p>
                    Don't let your e-books be shackled to the Kindle shores any
                    longer*. Hoist the colors, choose your plan, and join the
                    KNDL Kraken crew on an unforgettable adventure through the
                    boundless waters of e-book versatility. Remember, me mateys,
                    a small investment in KNDL Kraken PDF Unleashed unlocks a
                    world of endless possibilities for your e-book collection.
                    All hands on deck and set sail to conquer new reading
                    horizons! 🏴‍☠️
                  </p>
                  <p>
                    <em>
                      * Just a friendly nudge, me hearties, ensure you have the
                      proper permissions and licensing when convertin' yer
                      Kindle e-books into PDF format! Keep yer wits about you
                      an' navigate these waters responsibly.
                    </em>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-0 order-lg-2 col-md-8 offset-md-2 col-10 offset-1 order-1">
                <div className="about-section-image">
                  <div className="pattern-image pattern-image-1">
                    <div className="pattern-image-wrapper">
                      <img
                        style={{ backgroundColor: "#2863af" }}
                        className="drop-shadow-1"
                        src={pricing_image}
                        height="450" width={"500"}
                        alt="pattern"
                      />
                      <div className="background-pattern background-pattern-radius drop-shadow-1">
                        <BackgroundAnimation />
                        <div className="background-pattern-gradient"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* faq section - start */}
      <div className="faq-section faq-section-2">
        <div className="faq-section-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xxl-10 offset-xxl-1 col-lg-12 offset-lg-0 col-10 offset-1">
                <SectionHeading
                  icon="las la-file-alt"
                  heading="discover"
                  subHeading="Frequently asked questions"
                  additionalClasses="center width-64"
                />
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-9 col-md-8 col-10">
                <div className="faq-wrapper">
                  <div className="faq" id="faq-accordion">
                    {faq.map((element, key) => {
                      return (
                        <div className="accordion-item" key={key}>
                          <div className="accordion-header" id={`faq-${key}`}>
                            <button
                              className={`accordion-button ${
                                key !== 1 ? "collapsed" : ""
                              }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#faq-collapse-${key}`}
                              aria-expanded={key === 1 ? "true" : "false"}
                              aria-controls={`faq-collapse-${key}`}
                            >
                              <span>{element.question}</span>
                            </button>
                          </div>
                          <div
                            id={`faq-collapse-${key}`}
                            className={`accordion-collapse collapse ${
                              key === 1 ? "show" : ""
                            }`}
                            aria-labelledby={`faq-${key}`}
                            data-bs-parent="#faq-accordion"
                          >
                            <div className="accordion-body">
                              <p>{element.answer}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* faq section - end */}

      {/* instagram - start }
            <div className="instagram">
                <div className="instagram-wrapper">
                    <ImageSlider/>
                    <div className="button-wrapper">
                        <Button
                            to='/'
                            content='Follow us on Instagram'
                            type='button-premium'
                            icon={<i className="fab fa-instagram"></i>}
                        />
                    </div>
                </div>
            </div>
            { instagram - end */}
      <div className="row">
        <div className="col">
          <div className="container">
            <p>
              For Product Support, please contact the seller{" "}
              <a href="mailto:hello@kndlkraken.com">HERE</a>
            </p>
            <p>
              For Order Support, please contact ClickBank{" "}
              <a href="https://www.clkbank.com">HERE</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
