import React from "react";

import { Link } from "react-router-dom";

import PageHeader from "../components/PageHeader";
import RelatedPostSlider from "../components/RelatedPostSlider";
import CommentSingle from "../components/CommentSingle";
import comments from "../data/comments";
import BackgroundAnimation from "../components/BackgroundAnimation";
import ImageSlider from "../components/ImageSlider";
import Button from "../components/Button";

import ReactMarkdown from "react-markdown";
import rehypeExternalLinks from "rehype-external-links";

import { Helmet } from "react-helmet";
// images
import thumbnail from "../../assets/images/blog/blog-read-img.jpg";
import background_image_1 from "../../assets/images/patterns/pattern-1.jpg";

const Privacy = () => {
  const markdown = `
### Preamble
#### Chrome Web Store Privacy Notice
The use of information received from Google APIs will adhere to the Chrome Web Store User Data Policy, including the Limited Use requirements.

### Below is the Privacy Policy for this website and the software we provide.

### 1. Introduction

1.1 We are committed to safeguarding the privacy of our website visitors, service users, individual customers and customer personnel.

1.2 This policy applies where we are acting as a data controller with respect to the personal data of such persons; in other words, where we determine the purposes and means of the processing of that personal data.

1.3 Our website incorporates privacy controls which affect how we will process your personal data. By using the privacy controls, you can limit the collection, sharing of your personal data. You can access the privacy controls via link to "cookie settings" in the footer of every page.

1.4 We use cookies on our website. Insofar as those cookies are not strictly necessary for the provision of our website and services, we will ask you to consent to our use of cookies when you first visit our website.

1.5 In this policy, "we", "us" and "our" refer to Habanero Business Development AB. [For more information about us, see Section 14.]

### 2. Credit

2.1 This document was created using a template from Docular ([https://seqlegal.com/free-legal-documents/privacy-policy](https://seqlegal.com/free-legal-documents/privacy-policy)).

### 3. The personal data that we collect

3.1 In this Section 3 we have set out the general categories of personal data that we process[and, in the case of personal data that we did not obtain directly from you, information about the source and specific categories of that data.

3.2 We may process data enabling us to get in touch with you ("**contact data**"). The contact data may include your name, email address, telephone number, postal address and/or social media account identifiers. The source of the contact data is you. If you log into our website, service or software using a social media or federated account provider, we will obtain elements of the contact data from the relevant social media or federated account provider.

3.3 We may process user account data ("**account data**"). The account data may include your account identifier, name, email address, business name, account creation and modification dates, website settings and marketing preferences. The primary source of the account data is you, although some elements of the account data may be generated by our website, service or software. If you log into our website using a social media or federated account provider, we will obtain elements of the account data from the relevant social media or federated account provider.

3.4 We may process information relating to transactions, including purchases of goods and/or services, that you enter into with us and/or through our website ("**transaction data**").The transaction data may include your name, your contact details, your payment details and the transaction details. It will never contain your bank account details or credit card numbers. The source of the transaction data is you and/or our payment services provider.

3.5 We may process information contained in or relating to any communication that you send to us or that we send to you ("**communication data**"). The communication data may include the communication content and metadata associated with the communication. Our website will generate the metadata associated with communications made using the website contact forms.

3.6 We may process data about your use of our website and services ("**usage data**"). The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is our analytics tracking system.

### 4. Purposes of processing and legal bases

4.1 In this Section 4, we have set out the purposes for which we may process personal data and the legal bases of the processing.

4.2 **Operations** - We may process your personal data for the purposes of operating our website, the processing and fulfilment of orders, providing our services, supplying our software, generating invoices, bills and other payment-related documentation, and credit control. The legal basis for this processing is our legitimate interests, namely the proper administration of our website, services and business OR the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.

4.3 **Publications** - We may process account data for the purposes of publishing such data on our website and elsewhere through our services in accordance with your express instructions. The legal basis for this processing is consent.

4.4 **Relationships and communications** - We may process contact data, account data, transaction data and/or communication data for the purposes of managing our relationships, communicating with you (excluding communicating for the purposes of direct marketing) by email, SMS, post, fax and/or telephone, providing support services and complaint handling. The legal basis for this processing is our legitimate interests, namely communications with our website visitors, service users, individual customers and customer personnel, the maintenance of relationships, and the proper administration of our website, services and business.

4.5 **Direct marketing** - We may process contact data, account data and/or transaction data for the purposes of creating, targeting and sending direct marketing communications by email, SMS, post and/or fax and making contact by telephone for marketing-related purposes. The legal basis for this processing is consent.

4.6 **Research and analysis** - We may process usage data and/or transaction data for the purposes of researching and analysing the use of our website and services, as well as researching and analysing other interactions with our business. The legal basis for this processing is our legitimate interests, namely monitoring, supporting, improving and securing our website, services and business generally.

4.7 **Record keeping** - We may process your personal data for the purposes of creating and maintaining our databases, back-up copies of our databases and our business records generally. The legal basis for this processing is our legitimate interests, namely ensuring that we have access to all the information we need to properly and efficiently run our business in accordance with this policy.

4.8 **Security** - We may process your personal data for the purposes of security and the prevention of fraud and other criminal activity. The legal basis of this processing is our legitimate interests, namely the protection of our website, services and business, and the protection of others.

4.9 **Insurance and risk management** - We may process your personal data where necessary for the purposes of obtaining or maintaining insurance coverage, managing risks and/or obtaining professional advice. The legal basis for this processing is our legitimate interests, namely the proper protection of our business against risks.

4.10 **Legal claims** - We may process your personal data where necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this processing is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights and the legal rights of others.

4.11 **Legal compliance and vital interests** - We may also process your personal data where such processing is necessary for compliance with a legal obligation to which we are subject or in order to protect your vital interests or the vital interests of another natural person.

### 5. Providing your personal data to others

5.1 We may disclose your personal data to our insurers and/or professional advisers insofar as reasonably necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, obtaining professional advice.

5.2 Your personal data will be stored on the servers of our hosting services providers: Microsoft Azure and Cloudflare.

5.3 We may disclose personal data to our suppliers insofar as reasonably necessary for providing agreed upon software to you.

5.4 Financial transactions relating to our website, services and software may be handled by our seller of record - ClickBank. We will share transaction data with our service providers only to the extent necessary for the purposes of processing your payments, refunding such payments and dealing with complaints and queries relating to such payments and refunds. You can find information about the seller of record's privacy policies and practices at [https://support.clickbank.com/hc/en-us/articles/360004057131-ClickBank-Privacy-Policy](https://support.clickbank.com/hc/en-us/articles/360004057131-ClickBank-Privacy-Policy).

5.5 In addition to the specific disclosures of personal data set out in this Section 5, we may disclose your personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. We may also disclose your personal data where such disclosure is necessary for the establishment, exercise, or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.

### 6. International transfers of your personal data

6.1 In this Section 6, we provide information about the circumstances in which your personal data may be transferred to a third country under EU data protection law.

6.2 The hosting facilities for our services are situated in a globally distributed edge network. By consenting to our processing of your data or by purchasing services or software from us you accept and agree to all such processing, regardless of which jurisdiction your personal data will be stored or processed in.

6.3 You acknowledge that personal data that you submit for publication through our website or services may be available, via the internet, around the world. We cannot prevent the use (or misuse) of such personal data by others.

### 7. Retaining and deleting personal data

7.1 This Section 7 sets out our data retention policies and procedures, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal data.

7.2 Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.

7.3 We will retain your personal data as follows:

(a) contact data will be retained for a minimum period of 1 year following the date of the most recent contact between you and us, and may be stored indefinitely following that date;

(b) account data will be retained for a minimum period of 1 year following the date of closure of the relevant account, and for a maximum period of 5 years following that date;

(c) transaction data will be retained for a minimum period of 7 years following the date of the transaction, and may be stored indefinitely following that date;

(d) communication data will be retained for a minimum period of 1 year following the date of the communication in question, and may be stored indefinitely following that date;

(e) usage data will be retained for 5 years following the date of collection.

7.4 Notwithstanding the other provisions of this Section 7, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.

### 8. Your rights

8.1 In this Section 8, we have listed the rights that you have under data protection law.

8.2 Your principal rights under data protection law are:

(a) **the right to access** - you can ask for copies of your personal data;

(b) **the right to rectification** - you can ask us to rectify inaccurate personal data and to complete incomplete personal data;

(c) **the right to erasure** - you can ask us to erase your personal data;

(d) **the right to restrict processing** - you can ask us to restrict the processing of your personal data;

(e) **the right to object to processing** - you can object to the processing of your personal data;

(f) **the right to data portability** - you can ask that we transfer your personal data to another organisation or to you;

(g) **the right to complain to a supervisory authority** - you can complain about our processing of your personal data; and

(h) **the right to withdraw consent** - to the extent that the legal basis of our processing of your personal data is consent, you can withdraw that consent.

8.3 These rights are subject to certain limitations and exceptions. You can learn more about the rights of data subjects by visiting [[https://edpb.europa.eu/our-work-tools/general-guidance/gdpr-guidelines-recommendations-best-practices\_en](https://edpb.europa.eu/our-work-tools/general-guidance/gdpr-guidelines-recommendations-best-practices_en).

8.4 You may exercise any of your rights in relation to your personal data by written electronic notice to us.

### 9. About cookies

9.1 A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.

9.2 Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.

9.3 Cookies may not contain any information that personally identifies a user, but personal data that we store about you may be linked to the information stored in and obtained from cookies.

### 10. Cookies that we use

10.1 We use cookies for the following purposes:

(a) **authentication and status** - we use cookies to identify you when you visit our website and as you navigate our website, and to help us determine if you are logged into our website;

(b) **personalisation** - we use cookies to store information about your preferences and to personalise our website for you;

(c) **analysis** - we use cookies to help us to analyse the use and performance of our website and services; and

(d) **cookie consent** - we use cookies to store your preferences in relation to the use of cookies more generally.

### 11. Cookies used by our service providers

11.1 Our service providers use cookies and those cookies may be stored on your computer when you visit our website.

11.2 We use Google Analytics. Google Analytics gathers information about the use of our website by means of cookies. The information gathered is used to create reports about the use of our website. You can find out more about Google's use of information by visiting [https://www.google.com/policies/privacy/partners/](https://www.google.com/policies/privacy/partners/) and you can review Google's privacy policy at [https://policies.google.com/privacy](https://policies.google.com/privacy).

### 12. Managing cookies

12.1 Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:

(a) [https://support.google.com/chrome/answer/95647](https://support.google.com/chrome/answer/95647) (Chrome);

(b) [https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop](https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop) (Firefox);

(c) [https://help.opera.com/en/latest/security-and-privacy/](https://help.opera.com/en/latest/security-and-privacy/) (Opera);

(d) [https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies](https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies) (Internet Explorer);

(e) [https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac](https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac) (Safari); and

(f) [https://support.microsoft.com/en-gb/help/4468242/microsoft-edge-browsing-data-and-privacy](https://support.microsoft.com/en-gb/help/4468242/microsoft-edge-browsing-data-and-privacy) (Edge).

12.2 Blocking all cookies will have a negative impact upon the usability of many websites.

12.3 If you block cookies, you will not be able to use all the features on our website.

### 13. Amendments

13.1 We may update this policy from time to time by publishing a new version on our website.

13.2 You should check this page occasionally to ensure you are happy with any changes to this policy.

13.3 We may notify you of significant changes to this policy by email.

### 14. Our details

14.1 This website is owned and operated by Habanero Business Development AB.

14.2 We are registered in Sweden under registration number 556790-7232.

14.3 You can contact us:

(b) using our website contact form;

(d) by email, using the email address published on our website.

### 15. Representatives

15.1 Our representative within the EU with respect to our obligations under data protection law are the executives of the company.

`;
  return (
    <>
      <Helmet>
        <title>Privacy Policy | KNDL Kraken - PDF Unleashed</title>
        <meta title="robots" content="noindex" />
      </Helmet>
      {/* page header - start */}
      <PageHeader
        title="Privacy"
        pages={[
          {
            title: "Home",
            to: "/",
          },
        ]}
      />
      {/* page header - end */}
      <div className="row">
        <div className="col-md-8 offset-md-2 col-10 offset-1">
          <div className="blog-detail-content c-grey">
            <h1 className="c-dark f-w-700">Privacy Policy.</h1>
            <div className="blog-single-details">
              <div className="date">
                <i className="las la-calendar"></i>
                April 3, 2023
              </div>
            </div>
            <ReactMarkdown
              children={markdown}
              rehypePlugins={[rehypeExternalLinks]}
              linkTarget="_blank"
            ></ReactMarkdown>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
