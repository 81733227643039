import React, { useState, useRef, useEffect } from "react";

import { Link } from "react-router-dom";

// import Swiper React components
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { register } from "swiper/element/bundle";

const PricingSlider = ({ disablePurchase }) => {
  const [checked, setChecked] = useState(true);
  const ref = useRef(null);

  const price_number_height = () => {
    const prices = ref.current.querySelectorAll(".price");

    if (prices.length < 1) return;

    Array.from(prices).forEach((price) => {
      const month = price.querySelector(".month");
      const year = price.querySelector(".year");

      price.style.height =
        Math.max(month?.offsetHeight, year?.offsetHeight, 0) - 2 + "px";
      // price.style.width = Math.max(month.offsetWidth, year.offsetWidth) + 'px';
    });
  };

  useEffect(() => {
    register();
    if (ref.current) {
      ref.current.breakpoints = {
        0: {
          enabled: true,
          centeredSlides: true,
          slidesPerView: 1.2,
          spaceBetween: 10,
          initialSlide: 1,
        },
        400: {
          enabled: true,
          centeredSlides: true,
          slidesPerView: 1.4,
          spaceBetween: 10,
          initialSlide: 1,
        },
        576: {
          enabled: true,
          centeredSlides: true,
          slidesPerView: 2,
          spaceBetween: 10,
          initialSlide: 1,
        },
        768: {
          enabled: true,
          centeredSlides: true,
          slidesPerView: 2,
          spaceBetween: 15,
          initialSlide: 1,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 30,
          enabled: false,
          centeredSlides: false,
        },
      };

      ref.current.initialize();
      price_number_height();
      window.addEventListener("resize", price_number_height);
      return () => {
        window.removeEventListener("resize", price_number_height);
      };
    }
    //price_number_height();
  }, [ref]);

  useEffect(() => {
    const prices = ref.current.querySelectorAll(".price");

    if (prices.length < 1) return;

    if (checked) {
      Array.from(prices).forEach((price) => {
        if (price === undefined || price === null) return;
        price.classList.remove("price-month");
      });
    } else {
      Array.from(prices).forEach((price) => {
        if (price === undefined || price === null) return;
        price.classList.add("price-month");
      });
    }
  }, [checked, ref]);

  return (
    <div className="pricing">
      <div className="row">
        <div className="col">
          {/* <div className="switch">
            <div className="form-check form-switch">
              <label className="form-check-label" htmlFor="price-switch">
                Monthly
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="price-switch"
                onChange={() => {
                  setChecked(!checked);
                }}
                defaultChecked={checked}
              />
              <label className="form-check-label" htmlFor="price-switch">
                Annual
              </label>
            </div> 
          </div> */}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="pricing-slider">
            <swiper-container
              ref={ref}
              init="false"
              enabled="true"
              resize-observer="true"
              className="swiper-container"
              pagination="false"
              free-mode="true"
            >
              {/* <swiper-slide>
                <div className="pricing-single free">
                  <h5 className="plan">Free</h5>
                  <div className="price price-month">
                    <div className="month">
                      $<span className="number">10.00</span>
                      <sup>/mo</sup>
                    </div>
                    <div className="year">
                      $<span className="number">120.00</span>
                      <sup>/yr</sup>
                    </div>
                  </div>
                  <Link to="/contact" className="button button-free">
                    <div className="button-inner">
                      <div className="button-content">
                        <h4>Get Started</h4>
                      </div>
                    </div>
                  </Link>
                  <ul>
                    <li className="available">Chat with doctors</li>
                    <li className="available">Instant notifications</li>
                    <li className="available">Subscription delivery</li>
                    <li>Nutritional plans</li>
                    <li>Customizeable menus</li>
                    <li>Unlimited storage</li>
                    <li>Weekly reports</li>
                    <li>Fitness guides</li>
                  </ul>
                  <h6>No commitments, cancel anytime.</h6>
                </div>
              </swiper-slide>
              */}
              <swiper-slide className="swiper-slide">
                <div className="pricing-single basic">
                  <h5 className="plan">Lite</h5>
                  <div className="price price-month">
                    <div className="month">
                      $<span className="number">2</span>
                      <sup>/mo + tax</sup>
                      <h6>Paid quarterly, cancel anytime.</h6>
                    </div>
                    <div className="year"></div>
                  </div>
                  <PurchaseButton
                    type="basic"
                    disablePurchase={disablePurchase}
                    to="https://kndlkraken.pay.clickbank.net?cbitems=lite-kndlkraken-q-1"
                  ></PurchaseButton>
                  <ul>
                    <li className="available">100 PDFs / month</li>
                    <li className="available">250 pages / PDF</li>
                    <li className="available">Save any web page to PDF</li>
                    <li className="available">Convert Kindle books to PDF</li>
                    <li className="available">
                      <Link to="https://chrome.google.com/webstore/detail/ibhohffdkcilepcaimbcghekmhgpijfa/">
                        Easy to install Chrome Extension
                      </Link>
                    </li>
                    <li className="available">Immediate license activation</li>
                  </ul>
                </div>
              </swiper-slide>
              <swiper-slide className="swiper-slide">
                <div className="pricing-single standard">
                  <h5 className="plan">Pro</h5>
                  <div className="price price-month">
                    <div className="month">
                      $<span className="number">3</span>
                      <sup>/mo + tax</sup>
                      <h6>Paid quarterly, cancel anytime.</h6>
                    </div>
                    <div className="year"></div>
                  </div>
                  <PurchaseButton
                    type="standard"
                    disablePurchase={disablePurchase}
                    to="https://kndlkraken.pay.clickbank.net?cbitems=pro-kndlkraken-q-1"
                  ></PurchaseButton>
                  <ul>
                    <li className="available">250 PDFs / month</li>
                    <li className="available">1500 pages / PDF</li>
                    <li className="available">Save any web page to PDF</li>
                    <li className="available">Convert Kindle books to PDF</li>
                    <li className="available">
                      <Link to="https://chrome.google.com/webstore/detail/ibhohffdkcilepcaimbcghekmhgpijfa/">
                        Easy to install Chrome Extension
                      </Link>
                    </li>
                    <li className="available">Immediate license activation</li>
                  </ul>
                </div>
              </swiper-slide>
              <swiper-slide className="swiper-slide">
                <div className="pricing-single premium">
                  <h5 className="plan">Business</h5>
                  <div className="price price-month">
                    <div className="month">
                      $<span className="number">5</span>
                      <sup>/mo + tax</sup>
                      <h6>Paid quarterly, cancel anytime.</h6>
                    </div>
                    <div className="year"></div>
                  </div>
                  <PurchaseButton
                    type="premium"
                    disablePurchase={disablePurchase}
                    to="https://kndlkraken.pay.clickbank.net?cbitems=business-kndlkraken-q-1"
                  ></PurchaseButton>
                  <ul>
                    <li className="available">1000 PDFs / month</li>
                    <li className="available">no page limit!</li>
                    <li className="available">Save any web page to PDF</li>
                    <li className="available">Convert Kindle books to PDF</li>
                    <li className="available">
                      <Link to="https://chrome.google.com/webstore/detail/ibhohffdkcilepcaimbcghekmhgpijfa/">
                        Easy to install Chrome Extension
                      </Link>
                    </li>
                    <li className="available">Immediate license activation</li>
                  </ul>
                </div>
              </swiper-slide>
            </swiper-container>
          </div>
        </div>
      </div>
      <div className="about-section">
        <div className="about-section-wrapper">
          <div className="row">
            <div className="container">&nbsp;</div>
          </div>
          <div className="row">
            <div className="col">
              <div className="container">
                <p>
                  ClickBank is the retailer of products on this site. CLICKBANK®
                  is a registered trademark of Click Sales, Inc., a Delaware
                  corporation located at 1444 S. Entertainment Ave., Suite 410
                  Boise, ID 83709, USA and used by permission. ClickBank's role
                  as retailer does not constitute an endorsement, approval or
                  review of these products or any claim, statement or opinion
                  used in promotion of these products.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function PurchaseButton({ to, disablePurchase, type }) {
    const Wrapper = ({ children }) =>
      disablePurchase ? (
        <div className={`button button-${type ? type : "free"}`}>
          {children}
        </div>
      ) : (
        <Link
          to={to}
          className={`button button-${type ? type : "free"}`}
          target="cb"
        >
          {children}
        </Link>
      );

    return (
      <Wrapper>
        <div className="button-inner">
          <div className="button-content">
            <h4>{disablePurchase ? "Coming Soon!" : "Purchase"}</h4>
          </div>
        </div>
      </Wrapper>
    );
  }
};

export default PricingSlider;
