const faq = [
  {
    question: "Is there a free version?",
    answer:
      "Yes! KNDL Kraken PDF Unleashed is an extension for Google Chrome which you can freely install from the Chrome Web Store. Once installed you can use it directly for free. The free version has some limitations, it only can only create 25 PDFs per month with a maximum of 25 pages per PDF.",
  },
  {
    question: "Are there any requirements for using the extension?",
    answer:
      "The extension is available for Google Chrome. To use it, you need to have a Google account and have a Chrome Profile using the same email as you purchased your subscription with. You can download the extension from the Chrome Web Store.",
  },
  {
    question:
      "What payment methods do you accept for the KNDL Kraken PDF Unleashed extension?",
    answer:
      "Payment is made through ClickBank and they accept all major credit cards and PayPal.",
  },
  {
    question:
      "Can I switch between plans at any time? If so, how does that process work?",
    answer:
      "Yes, you can switch between plans at any time. You can do this by logging into your ClickBank account and changing your subscription. You can also contact us and we will assist you.",
  },
  {
    question:
      "Can I get a refund if I'm not happy with the product?",
    answer:
      "Yes, you have a 60 day refund period if the product doesn't work for you. Don't forget that you can test the free version before you purchase the extension. If you have any questions, please contact us.",
  },
  {
    question: "What happens if I exceed my monthly PDF conversion limit?",
    answer:
      "If you exceed your monthly PDF limit, you will be limited to 25 pages per PDF until the start of the next billing period.",
  },
  {
    question:
      "Are there any additional or hidden fees associated with any of the plans?",
    answer:
      "No, there are no additional or hidden fees associated with any of the plans.",
  },
  {
    question: "How is the extension billed - monthly or quarterly?",
    answer:
      "The extension is billed quarterly. If you cancel your subscription, you will continue to have access to the extension until the end of your current billing period. After that you will have the same limitations as the free version.",
  },
];

export default faq;
