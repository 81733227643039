import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';

//createRoot is a new feature in React 18



const rootElement = document.getElementById('root');
createRoot(rootElement).render(<React.StrictMode>
  <App />
</React.StrictMode>
);


