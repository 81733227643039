const features = [
    {
        icon: 'las la-comments',
        heading: 'Effortless Conversion',
        excerpt: 'Transform Kindle e-books into PDFs with just a few clicks. Simplify your reading experience and access your books in a format you love.',
        to: '/feature-detail-1'
    },
    {
        icon: 'las la-headset',
        heading: 'Familiar Tools Unleashed',
        excerpt: 'Annotate, highlight, and take notes directly in the converted e-books. Maximize productivity with the PDF tools you know and trust.',
        to: '/feature-detail-1'
    },
    {
        icon: 'las la-photo-video',
        heading: 'Versatile Compatibility',
        excerpt: 'Enjoy your e-books across a wide range of devices. Read and review your literature on laptops, tablets, or mobile devices seamlessly.',
        to: '/feature-detail-1'
    },
    {
        icon: 'las la-server',
        heading: 'Streamlined Workflow',
        excerpt: 'Stay productive by working with your e-books in a single, universally accessible format. Good-bye format switching - hello  efficiency!',
        to: '/feature-detail-1'
    },
    {
        icon: 'las la-user-lock',
        heading: 'Flexible Pricing Options',
        excerpt: 'Choose from a variety of pricing plans tailored to your needs. From a free plan to lifetime access, there\'s an option for every budget.',
        to: '/feature-detail-1'
    },
    {
        icon: 'las la-map-marked-alt',
        heading: 'Secure and Discreet',
        excerpt: 'Rest assured that your personal data remains safe, as our extension doesn\'t show ads or sell your information.',
        to: '/feature-detail-1'
    }
];

export default features;