import React from 'react';

// components
//import DownloadButton from '../components/DownloadButton';
import SectionHeading from '../components/SectionHeading';
import AppFeatureSingle from '../components/AppFeatureSingle';
/*import Button from '../components/Button';
import PricingSlider from '../components/PricingSlider';
import TestimonialSlider from '../components/TestimonialSlider';
import ScreenSlider from '../components/ScreenSlider';
import ContactForm1 from '../components/ContactForm1';
import ContactForm2 from '../components/ContactForm2';
import BlogSingle from '../components/BlogSingle';*/
import BackgroundAnimation from '../components/BackgroundAnimation';
//import VideoLightBox from '../components/VideoLightBox';

// images
import hero_phone from '../../assets/images/kraken-hero-small.png';
import video_img from '../../assets/images/book-placeholder.png';
import background_image_1 from '../../assets/images/patterns/pattern-1.jpg';
/*import background_image_2 from '../../assets/images/patterns/pattern-2.jpg';
import feature_phone_1 from '../../assets/images/feature-section-1-phone.png';
import feature_image_1 from '../../assets/images/feature-section-1-img.jpg';
import feature_phone_2 from '../../assets/images/feature-section-2-phone.png';
import feature_image_2 from '../../assets/images/feature-section-2-img.jpg';
*/
// data
//import blog from '../data/blog';
import features from '../data/features';
//import faq from '../data/faq';

const Index = () => {

    return (
        <>
            {/* hero - start */}
            <div className="hero hero-1">
                <div className="hero-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-0 order-lg-1 col-10 offset-1 order-2">
                                <div className="hero-content c-white">
                                    <h1>Unleash the KNDL Kraken: ⚓️📚 Your PDF Voyage Awaits!</h1>
                                    <p className="large">Ahoy, book lovers and literary adventurers!
                                        Set sail on the high seas of e-books and explore new horizons with our
                                        KNDL Kraken PDF Unleashed Chrome extension. Navigate uncharted territories
                                        with ease as you transform e-books and web pages into accessible PDFs, ready for
                                        all your reading and annotating adventures. Chart your course to
                                        discovery today, me hearties! 🏴‍☠️</p>
                                    {/*<DownloadButton buttonType='download-button-2'/>*/}
                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-0 order-lg-2 col-10 offset-1 order-1">
                                <div className="hero-image">
                                    <img className="drop-shadow" src={hero_phone} alt="hero-phone" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="background-pattern background-pattern-1">
                        <BackgroundAnimation />
                        <div className="background-pattern-gradient"></div>
                        <div className="background-pattern-bottom">
                            <div className="image" style={{ backgroundImage: `url(${background_image_1})` }}></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* hero - end */}

            {/* app feature - start */}
            <div className="app-feature app-feature-1">
                <div className="app-feature-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-10 offset-1">
                                <SectionHeading
                                    heading='app features'
                                    subHeading='Amazing features.'
                                    icon='las la-cog'
                                    additionalClasses='center width-64'
                                />
                            </div>
                        </div>
                        <div className="row gx-5 gy-5">
                            {features.map((element, key) => {
                                return (
                                    <div className="col-lg-4 offset-lg-0 col-md-6 offset-md-0 col-10 offset-1" key={key}>
                                        <AppFeatureSingle
                                            icon={element.icon}
                                            heading={element.heading}
                                            excerpt={element.excerpt}
                                            containerClass='app-feature-single-2'
                                            link={element.to}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            {/* app feature - end */}

            {/* video - start */}
            <div className="video-section">
                <div className="video-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 offset-lg-1 order-lg-1 col-10 offset-1 order-2">
                                <div className="video-section-content">
                                    <div className="section-heading section-heading-1 center-responsive c-white">
                                        <div className="sub-heading upper ls-1">
                                            <i className="las la-video"></i>
                                            <h5>Ahoy Matey!</h5>
                                        </div>
                                        <div className="main-heading">
                                            <h1>Smooth sailing with KNDL Kraken PDF Unleashed! Finally, Kindle e-books and web pages can be in PDF format, making annotating and reading a true breeze. A must-have for any PDF enthusiast!</h1>
                                        </div>
                                    </div>
                                    {/*<Button
                                        to="/contact"
                                        content="Get Started"
                                        type="button-1"
                                    />*/}
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-1 order-lg-2 order-1">
                                <div className="video-section-video">
                                    <figure>
                                        <img className="drop-shadow-1" src={video_img} width='471' height='472' alt="drop-shadow" />

                                        {/*<div className="play">
                                            <VideoLightBox URL='https://www.youtube.com/watch?v=WIl5F5rM5wQ' />
                                        </div>*/}
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="background-pattern background-pattern-radius drop-shadow-1">
                            <BackgroundAnimation />
                            <div className="background-pattern-gradient"></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* video - end */}


        </>
    );
};

export default Index;
