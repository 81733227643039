import React from "react";
import PageHeader from "../components/PageHeader";
/*import TeamSlider from '../components/TeamSlider';
import ImageSlider from '../components/ImageSlider';
import Button from '../components/Button';
import SectionHeading from '../components/SectionHeading';
import TestimonialSlider from '../components/TestimonialSlider';*/
import BackgroundAnimation from "../components/BackgroundAnimation";
//import faq from '../data/faq';

// images
//import background_image_1 from '../../assets/images/patterns/pattern-1.jpg';
import about_image from "../../assets/images/about-image-1.jpg";
import { Helmet } from "react-helmet";
//import AppFeatureSingle from '../components/AppFeatureSingle';

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Not Found</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      {/* page header - start */}
      <PageHeader
        title="Not Found!"
        pages={[
          {
            title: "Home",
            to: "/",
          },
        ]}
      />
      {/* page header - end */}
      {/* about section - start */}
      <div className="about-section">
        <div className="about-section-wrapper">
          <div className="container">
            {/* first half - start */}
            <div className="row">
              <div className="col-lg-6 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2">
                <div className="about-section-content c-grey">
                  <div className="section-heading">
                    <div className="sub-heading c-blue upper ls-1">
                      <i className="las la-user-circle"></i>
                      <h5>Not Found</h5>
                    </div>
                    <div className="main-heading c-dark">
                      <h1>The page you are looking for does not exist.</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-0 order-lg-2 col-md-8 offset-md-2 col-10 offset-1 order-1">
                <div className="about-section-image">
                  <div className="pattern-image pattern-image-1">
                    <div className="pattern-image-wrapper">
                      <img
                        className="drop-shadow-1"
                        src={about_image}
                        height="450"
                        alt="pattern"
                      />
                      <div className="background-pattern background-pattern-radius drop-shadow-1">
                        <BackgroundAnimation />
                        <div className="background-pattern-gradient"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
