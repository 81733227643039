import React from "react";

import { Link } from "react-router-dom";

import PageHeader from "../components/PageHeader";
import RelatedPostSlider from "../components/RelatedPostSlider";
import CommentSingle from "../components/CommentSingle";
import comments from "../data/comments";
import BackgroundAnimation from "../components/BackgroundAnimation";
import ImageSlider from "../components/ImageSlider";
import Button from "../components/Button";

import ReactMarkdown from "react-markdown";
import rehypeExternalLinks from "rehype-external-links";

// images
import thumbnail from "../../assets/images/blog/blog-read-img.jpg";
import background_image_1 from "../../assets/images/patterns/pattern-1.jpg";
import { Helmet } from "react-helmet";

const Eula = () => {
  const markdown = `
Please read this EULA carefully, as it sets out the basis upon which we license the Software for use. Your personal data is processed in accordance with our [Privacy Policy](/privacy).

By using, installing or purchasing the Software, you agree to be bound by the provisions of this EULA. If you do not agree to be bound by the provisions of this EULA, you must not use, install or purchase the Software. A link to this license is provided in the Software.

By agreeing to be bound by this EULA, you further agree that your employees or any person you authorise to use the Software will comply with the provision of this EULA.

### 1. Definitions

#### 1.1 In this EULA, except to the extent expressly provided otherwise:

"**Charges**" means those amounts that the parties have agreed shall be payable by the User to the Licensor in respect of this EULA;

"**Documentation**" means the documentation for the Software produced by the Licensor and made available by the Licensor to the User;

"**Effective Date**" means the date upon which the User first gives the User's express consent to this EULA, following the issue of this EULA by the Licensor;

"**Renewal Date**" means the date upon which the User renews their use of the Software, following the expiry of the Initial Term or any Renewal Term;

"**EULA**" means this end user licence agreement, including any amendments to this end user licence agreement from time to time;

"**Force Majeure Event**" means an event, or a series of related events, that is outside the reasonable control of the party affected (which may include failures of the internet or any public telecommunications network, hacker attacks, denial of service attacks, virus or other malicious software attacks or infections, power failures, industrial disputes affecting any third party, changes to the law, disasters, epidemics, pandemics, explosions, fires, floods, riots, terrorist attacks and wars);

"**Intellectual Property Rights**" means all intellectual property rights wherever in the world, whether registrable or unregistrable, registered or unregistered, including any application or right of application for such rights (and these "intellectual property rights" include copyright and related rights, database rights, confidential information, trade secrets, know-how, business names, trade names, trade marks, service marks, passing off rights, unfair competition rights, patents, petty patents, utility models, semi-conductor topography rights and rights in designs);

"**Licensor**" means Habanero Business Development AB, a company incorporated in Sweden (registration number 556790-7232);

"**Licensor Indemnity Event**" has the meaning given to it in Clause 13.1;

"**Maintenance Services**" means the supply to the User and application to the Software of Updates and Upgrades;

"**Initial Term**" means, in respect of this EULA, the period of 3 months beginning on the Effective Date;

"**Renewal Term**" means, in respect of this EULA, the period of 3 months beginning on the Renewal Date;

"**Services**" means any services that the Licensor provides to the User, or has an obligation to provide to the User, under this EULA;

"**Software**" means KNDL KRAKEN - PDF UNLEASHED;

"**Software Defect**" means a defect, error or bug in the Software having a material adverse effect on the appearance, operation, functionality or performance of the Software, but excluding any defect, error or bug caused by or arising as a result of:

(a) any act or omission of the User or any person authorised by the User to use the Software;

(b) any use of the Software contrary to the Documentation by the User or any person authorised by the User to use the Software;

(c) a failure of the User to perform or observe any of its obligations in this EULA; and/or

(d) an incompatibility between the Software and any other system, network, application, program, hardware or software not specified as compatible in the Software Specification;

"**Software Specification**" means the description for the Software set out in the Documentation;

"**Source Code**" means the Software code in human-readable form or any part of the Software code in human-readable form, including code compiled to create the Software or decompiled from the Software, but excluding interpreted code comprised in the Software;

"**Support Services**" means support in relation to the use of the Software and the identification and resolution of errors in the Software, but shall not include the provision of training services whether in relation to the Software or otherwise;

"**Term**" means the term of this EULA, commencing in accordance with Clause 3.1 and ending in accordance with Clause 3.2;

"**Update**" means a hotfix, patch or minor version update to the Software;

"**Upgrade**" means a major version upgrade of the Software;

"**User**" means the person to whom the Licensor grants a right to use the Software under this EULA; and

"**User Indemnity Event**" has the meaning given to it in Clause 13.3.

### 2. Credit

2.1 This document was created using a template from Docular ([https://seqlegal.com/free-legal-documents/eula](https://seqlegal.com/free-legal-documents/eula)).

### 3. Term

3.1 This EULA shall come into force upon the Effective Date.

3.2 This EULA shall continue in force indefinitely, subject to termination in accordance with Clause 15 or any other provision of this EULA.

### 4. Licence

4.1 The Licensor hereby grants to the User from the date of supply of the Software to the User until the end of the Term a worldwide, non-exclusive licence to:

(a) install the Software;

(b) use the Software in accordance with the Documentation; and

(c) create, store and maintain back-up copies of the Software,

subject to the limitations and prohibitions set out and referred to in this Clause 4.

4.2 The User may not sub-license and must not purport to sub-license any rights granted under Clause 4.1 without the prior written consent of the Licensor.

4.3 Save to the extent expressly permitted by this EULA or required by applicable law on a non-excludable basis, any licence granted under this Clause 4 shall be subject to the following prohibitions:

(a) the User must not sell, resell, rent, lease, loan, supply, publish, distribute or redistribute the Software;

(b) the User must not alter, edit or adapt the Software; and

(c) the User must not decompile, de-obfuscate or reverse engineer, or attempt to decompile, de-obfuscate or reverse engineer, the Software.

4.4 The User shall be responsible for the security of copies of the Software supplied to the User under this EULA (or created from such copies) and shall use all reasonable endeavours (including all reasonable security measures) to ensure that access to such copies is restricted to persons authorised to use them under this EULA.

### 5. Source Code

5.1 Nothing in this EULA shall give to the User or any other person any right to access or use the Source Code or constitute any licence of the Source Code.

### 6. Maintenance Services

6.1 The Licensor shall provide basic Maintenance Services to the User.

6.2 The Licensor shall provide the Maintenance Services with reasonable skill and care.

6.3 The Licensor does not warrant to the User that the application of Updates and Upgrades to the Software by the Licensor will not introduce any Software Defects into the Software.

6.4 The Licensor may suspend the provision of the Maintenance Services if the User is using a free version of the Software or if any amount due to be paid by the User to the Licensor under this EULA is overdue.

6.5 The Licensor may charge the User for the provision of the Maintenance Services in accordance with the Charges.

6.6 The Licensor may terminate the Maintenance Services to the User without notice.

6.7 If the Licensor stops or makes a good faith decision to stop providing maintenance services in relation to the Software to its customers generally, then the Licensor may terminate the Maintenance Services by giving at least 90 days' written notice of termination to the User.

6.8 If the Maintenance Services are terminated in accordance with the provisions of this Clause 6:

(a) the User must pay to the Licensor any outstanding Charges in respect of Maintenance Services provided to the User before the termination of the Maintenance Services;

(b) the Licensor must refund to the User any Charges paid by the User to the Licensor in respect of Maintenance Services that were to be provided to the User after the termination of the Maintenance Services; and

(c) the provisions of this Clause 6, excluding this Clause 6.8, shall cease to apply, but the other provisions of this EULA will continue notwithstanding such termination.

### 7. Support Services

7.1 The Licensor shall provide basic Support Services to the User.

7.2 The Licensor shall provide the Support Services with reasonable skill and care.

7.3 The Licensor may suspend the provision of the Support Services if the User is using a free version of the Software or if any amount due to be paid by the User to the Licensor under this EULA is overdue.

7.4 The Licensor may terminate the Support Services to the User without notice.

7.5 If the Support Services are terminated in accordance with the provisions of this Clause 7:

(a) the User must pay to the Licensor any outstanding Charges in respect of Support Services provided to the User before the termination of the Support Services;

(b) the Licensor must refund to the User any Charges paid by the User to the Licensor in respect of Support Services that were to be provided to the User after the termination of the Support Services; and

(c) the provisions of this Clause 7, excluding this Clause 7.5, shall cease to apply, but the other provisions of this EULA will continue notwithstanding such termination.

### 8. No assignment of Intellectual Property Rights

8.1 Nothing in this EULA shall operate to assign or transfer any Intellectual Property Rights from the Licensor to the User, or from the User to the Licensor.

### 9. Charges

9.1 The User shall pay the Charges to the Licensor in accordance with this EULA.

9.2 All amounts stated in or in relation to this EULA are, unless the context requires otherwise, stated exclusive of any applicable value added taxes, which will be added to those amounts and payable by the User to the Licensor.

### 10. Payments

10.1 The Licensor shall issue a receipt for the Charges to the User upon payment.

10.2 The User must pay the Charges to the Licensor in advance.

10.3 The User must pay the Charges by using such payment details as are notified by the Licensor to the User from time to time.

10.4 If the User does not pay any amount properly due to the Licensor under this EULA, the Licensor may:

(a) disable all use of the Software.

### 11. Warranties

11.1 The Licensor warrants to the User that it has the legal right and authority to enter into this EULA and to perform its obligations under this EULA.

11.2 The Licensor does not warrant to the User that:

(a) the Software as provided will conform in all respects with the Software Specification;

(b) the Software will be supplied free from Software Defects;

(c) the Software will be fit for any particular purpose; or

(d) the Software will be compatible with any other software or hardware.

11.3 The Licensor warrants to the User that the Software, when used by the User in accordance with this EULA, will not breach any laws, statutes or regulations applicable under Swedish.

11.4 The User warrants to the Licensor that it has the legal right and authority to enter into this EULA and to perform its obligations under this EULA.

11.5 All of the parties' warranties and representations in respect of the subject matter of this EULA are expressly set out in this EULA. To the maximum extent permitted by applicable law, no other warranties or representations concerning the subject matter of this EULA will be implied into this EULA or any related contract.

### 12. Acknowledgements and warranty limitations

12.1 The User acknowledges that complex software is never wholly free from defects, errors and bugs; and subject to the other provisions of this EULA, the Licensor gives no warranty or representation that the Software will be wholly free from defects, errors and bugs.

12.2 The User acknowledges that complex software is never entirely free from security vulnerabilities; and subject to the other provisions of this EULA, the Licensor gives no warranty or representation that the Software will be entirely secure.

12.3 The User acknowledges that the Software is only designed to be compatible with that software specified as compatible in the Software Specification; and the Licensor does not warrant or represent that the Software will be compatible with any other software.

12.4 The User acknowledges that the Licensor will not provide any legal advice under this EULA or in relation to the Software; and, except to the extent expressly provided otherwise in this EULA, the Licensor does not warrant or represent that the Software or the use of the Software by the User will not give rise to any legal liability on the part of the User or any other person.

### 13. Indemnities

13.1 The Licensor shall indemnify and shall keep indemnified the User against any and all liabilities, damages, losses, costs and expenses (including legal expenses and amounts paid in settlement of legal claims) suffered or incurred by the User pursuant to any infringement by the Licensor of any third party's Intellectual Property Rights or any applicable Swedish law (a "**Licensor Indemnity Event**").

13.2 The User must:

(a) upon becoming aware of an actual or potential Licensor Indemnity Event, notify the Licensor;

(b) provide to the Licensor all such assistance as may be reasonably requested by the Licensor in relation to the Licensor Indemnity Event;

(c) allow the Licensor the exclusive conduct of all disputes, proceedings, negotiations and settlements with third parties relating to the Licensor Indemnity Event; and

(d) not admit liability to any third party in connection with the Licensor Indemnity Event or settle any disputes or proceedings involving a third party and relating to the Licensor Indemnity Event without the prior written consent of the Licensor and the Licensor's obligation to indemnify the User under Clause 13.1 shall not apply unless the User complies with the requirements of this Clause 13.2.

13.3 The User shall indemnify and shall keep indemnified the Licensor against any and all liabilities, damages, losses, costs and expenses (including legal expenses and amounts paid in settlement of legal claims) suffered or incurred by the Licensor pursuant any breach or alleged breach by the User of any third party's Intellectual Property Rights, any applicable law, or any provision of this EULA (a "**User Indemnity Event**").

13.4 The Licensor must:

(a) upon becoming aware of an actual or potential User Indemnity Event, notify the User;

(b) provide to the User all such assistance as may be reasonably requested by the User in relation to the User Indemnity Event;

(c) allow the User the exclusive conduct of all disputes, proceedings, negotiations and settlements with third parties relating to the User Indemnity Event; and

(d) not admit liability to any third party in connection with the User Indemnity Event or settle any disputes or proceedings involving a third party and relating to the User Indemnity Event without the prior written consent of the User.

13.5 The indemnity protection set out in this Clause 13 shall be subject to the limitations and exclusions of liability set out in this EULA, except where the User breaches any third party's intellectual property rights.

### 14. Limitations and exclusions of liability

14.1 Nothing in this EULA will:

(a) limit any liabilities in any way that is not permitted under applicable law; or

(b) exclude any liabilities that may not be excluded under applicable law,

and, if a party is a consumer, that party's statutory rights will not be excluded or limited by this EULA, except to the extent permitted by law.

14.2 The limitations and exclusions of liability set out in this Clause 14 and elsewhere in this EULA:

(a) are subject to Clause 14.1; and

(b) govern all liabilities arising under this EULA or relating to the subject matter of this EULA, including liabilities arising in contract, in tort (including negligence) and for breach of statutory duty, except to the extent expressly provided otherwise in this EULA.

14.3 The Licensor will not be liable to the User in respect of any losses arising out of a Force Majeure Event.

14.4 The Licensor will not be liable to the User in respect of any loss of profits or anticipated savings.

14.5 The Licensor will not be liable to the User in respect of any loss of revenue or income.

14.6 The Licensor will not be liable to the User in respect of any loss of business, contracts or opportunities.

14.7 The Licensor will not be liable to the User in respect of any loss or corruption of any data, database or software.

14.8 The Licensor will not be liable to the User in respect of any special, indirect or consequential loss or damage.

14.9 The liability of the Licensor to the User under this EULA in respect of any event or series of related events shall not exceed the total amount paid and payable by the User to the Licensor under this EULA in the 12 month period preceding the commencement of the event or events.

14.10 The aggregate liability of the Licensor to the User under this EULA shall not exceed the total amount paid and payable by the User to the Licensor under this EULA.

### 15. Termination

15.1 The Licensor may terminate this EULA by giving to the User written notice of termination.

15.2 The User may terminate this EULA by not renewing their subscription.

15.3 Either party may terminate this EULA immediately by giving written notice of termination to the other party if:

(a) the other party commits any material breach of this EULA;

15.4 Subject to applicable law, either party may terminate this EULA immediately by giving written notice of termination to the other party if:

(a) the other party:

(i) is dissolved;

(ii) ceases to conduct all (or substantially all) of its business;

(iii) is or becomes unable to pay its debts as they fall due;

(iv) is or becomes insolvent or is declared insolvent; or

(v) convenes a meeting or makes or proposes to make any arrangement or composition with its creditors;

(b) an administrator, administrative receiver, liquidator, receiver, trustee, manager or similar is appointed over any of the assets of the other party;

(c) an order is made for the winding up of the other party, or the other party passes a resolution for its winding up; or

(d) if that other party is an individual:

(i) that other party becomes incapable of managing his or her own affairs as a result of illness or incapacity; or

(ii) that other party is the subject of a bankruptcy petition or order,

and if that other party dies then this EULA shall automatically terminate.

15.5 The Licensor may terminate this EULA immediately by giving written notice to the User if:

(a) any amount due to be paid by the User to the Licensor under this EULA is unpaid by the due date and remains unpaid upon the date that that written notice of termination is given; and

**16. Effects of termination**

16.1 Upon the termination of this EULA, all of the provisions of this EULA shall cease to have effect, save that the following provisions of this EULA shall survive and continue to have effect (in accordance with their express terms or otherwise indefinitely): Clauses 1, 10.2, 10.4, 13, 14, 16, 17 and 18.

16.2 Except to the extent expressly provided otherwise in this EULA, the termination of this EULA shall not affect the accrued rights of either party.

16.3 Within 30 days following the termination of this EULA for any reason:

(a) the User must pay to the Licensor any Charges in respect of Services provided to the User before the termination of this EULA and in respect of licences in effect before the termination of this EULA; and

(b) the Licensor must refund to the User any Charges paid by the User to the Licensor in respect of Services that were to be (but are not) provided to the User after the termination of this EULA and in respect of licences that were to be (but are not) in effect after the termination of this EULA,

without prejudice to the parties' other legal rights.

16.4 For the avoidance of doubt, the licences of the Software in this EULA shall terminate upon the termination of this EULA; and, accordingly, the User must immediately cease to use the paid features of the Software upon the termination of this EULA.

**17. General**

17.1 No breach of any provision of this EULA shall be waived except with the express written consent of the party not in breach.

17.2 If any provision of this EULA is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions of this EULA will continue in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect (unless that would contradict the clear intention of the parties, in which case the entirety of the relevant provision will be deemed to be deleted).

17.3 This EULA may not be varied except by a written document signed by or on behalf of each of the parties.

17.4 The User hereby agrees that the Licensor may assign the Licensor's contractual rights and obligations under this EULA to any successor to all or a substantial part of the business of the Licensor from time to time. Save to the extent expressly permitted by applicable law, the User must not without the prior written consent of the Licensor assign, transfer or otherwise deal with any of the User's contractual rights or obligations under this EULA.

17.5 This EULA is made for the benefit of the parties and is not intended to benefit any third party or be enforceable by any third party. The rights of the parties to terminate, rescind, or agree any amendment, waiver, variation or settlement under or relating to this EULA are not subject to the consent of any third party.

17.6 Subject to Clause 14.1, this EULA shall constitute the entire agreement between the parties in relation to the subject matter of this EULA, and shall supersede all previous agreements, arrangements and understandings between the parties in respect of that subject matter.

17.7 This EULA shall be governed by and construed in accordance with Swedish law.

17.8 The courts of Sweden shall have exclusive jurisdiction to adjudicate any dispute arising under or in connection with this EULA.

### 18. Interpretation

18.1 In this EULA, a reference to a statute or statutory provision includes a reference to:

(a) that statute or statutory provision as modified, consolidated and/or re-enacted from time to time; and

(b) any subordinate legislation made under that statute or statutory provision.

18.2 The Clause headings do not affect the interpretation of this EULA.

18.3 References in this EULA to "calendar months" are to the 12 named periods (January, February and so on) into which a year is divided.

18.4 In this EULA, general words shall not be given a restrictive interpretation by reason of being preceded or followed by words indicating a particular class of acts, matters or things.
`;
  return (
    <>
      <Helmet>
        <title>End User License Agreement | KNDL Kraken - PDF Unleashed</title>
        <meta title="robots" content="noindex" />
      </Helmet>
      {/* page header - start */}
      <PageHeader
        title="License Agreement"
        pages={[
          {
            title: "Home",
            to: "/",
          },
        ]}
      />
      {/* page header - end */}
      <div className="row">
        <div className="col-md-8 offset-md-2 col-10 offset-1">
          <div className="blog-detail-content c-grey">
            <h1 className="c-dark f-w-700">End User License Agreement.</h1>
            <div className="blog-single-details">
              <div className="date">
                <i className="las la-calendar"></i>
                April 3, 2023
              </div>
            </div>
            <ReactMarkdown
              children={markdown}
              rehypePlugins={[rehypeExternalLinks]}
              linkTarget="_blank"
            ></ReactMarkdown>
          </div>
        </div>
      </div>
    </>
  );
};

export default Eula;
