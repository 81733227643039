import { useEffect } from "react";

export function useClickbankBanner() {
  useEffect(() => {
    //insert script
    const script = document.createElement("script");
    script.src = "//cbtb.clickbank.net/?vendor=kndlkraken";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      const cbs = document.querySelectorAll(".cbtb");
      cbs.forEach((cb) => cb.remove());
      script.remove();
    };
  });
}
